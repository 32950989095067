import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';
import {
  eShopTypes,
  instantEshopOptions,
  PACKS_LISTING_TYPE,
  packsPaymentOptions,
} from '../../config/configListing';
import { types as sdkTypes } from '../../util/sdkLoader';
import { useConfiguration } from '../../context/configurationContext';
import { formatMoney } from '../../util/currency';
const { Money } = sdkTypes;
const SectionEshop = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;

  if (!publicData) {
    return null;
  }
  const { stockType, instantlyAvailable, listingType } = publicData;
  const isEshop = listingType === 'eShop';
  if (!isEshop) return null;
  const stockTypeFound = stockType ? eShopTypes?.find(conf => conf.value === stockType) : null;
  const instantlyAvailableFound = instantlyAvailable
    ? instantEshopOptions?.find(conf => conf.value === instantlyAvailable)
    : null;
  const isInstantlyAvailableNo = instantlyAvailable === 'no';
  return publicData && isEshop ? (
    <div className={css.sectionDetails}>
      <ul className={css.details}>
        {stockTypeFound && stockTypeFound?.label ? (
          <li key={'stockType'} className={css.detailsRow}>
            <span className={css.detailLabel}>
              {intl.formatMessage({ id: 'ListingPage.SectionEshop.typeOfProduct' })}
            </span>
            <span>
              <FormattedMessage id={stockTypeFound?.label} />
            </span>
          </li>
        ) : null}
        {instantlyAvailableFound && instantlyAvailableFound?.label ? (
          <li key={'stockType'} className={css.detailsRow}>
            <span className={css.detailLabel}>
              {intl.formatMessage({ id: 'ListingPage.SectionEshop.isProductAvailabel' })}
            </span>
            <span>
              <FormattedMessage id={instantlyAvailableFound?.label} />
            </span>
          </li>
        ) : null}
        {isInstantlyAvailableNo && instantlyAvailableFound ? (
          <li key={'note'} className={css.detailsRow}>
            <span>{intl.formatMessage({ id: 'ListingPage.SectionEshop.noNote' })}</span>
          </li>
        ) : null}
      </ul>
    </div>
  ) : null;
};

export default SectionEshop;
