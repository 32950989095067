import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';
import { PACKS_LISTING_TYPE, packsPaymentOptions } from '../../config/configListing';
import { types as sdkTypes } from '../../util/sdkLoader';
import { useConfiguration } from '../../context/configurationContext';
import { formatMoney } from '../../util/currency';
const { Money } = sdkTypes;
const SectionPacks = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;

  if (!publicData) {
    return null;
  }
  const {
    paymentOption,
    numberOfInstallments,
    installmentPrice,
    downPaymentPrice,
    listingType,
  } = publicData;
  const isPackListing = listingType === PACKS_LISTING_TYPE;
  if (!isPackListing) return null;
  const foundOption = paymentOption
    ? packsPaymentOptions?.find(p => p.value == paymentOption)
    : null;
  const config = useConfiguration();
  const installmentPriceNewMoney = installmentPrice
    ? new Money(installmentPrice, config.currency)
    : null;
  const installmentFormat = installmentPriceNewMoney
    ? formatMoney(intl, installmentPriceNewMoney)
    : null;
  const downPaymentPriceNewMoney = downPaymentPrice
    ? new Money(downPaymentPrice, config.currency)
    : null;
  const downPaymentFormat = downPaymentPriceNewMoney
    ? formatMoney(intl, downPaymentPriceNewMoney)
    : null;
  return publicData && isPackListing ? (
    <div className={css.sectionDetails}>
      {/* <Heading as="h2" rootClassName={css.sectionHeading}>
        Payment Type
      </Heading> */}
      <ul className={css.details}>
        {foundOption && foundOption?.label ? (
          <li key={'paymentOption'} className={css.detailsRow}>
            <span className={css.detailLabel}>
              <FormattedMessage id="ListingPage.SectionPacks.paymentType" />
            </span>
            <span>
              <FormattedMessage id={foundOption?.label} />
            </span>
          </li>
        ) : null}
        {numberOfInstallments ? (
          <li key={'numberOfInstallments'} className={css.detailsRow}>
            <span className={css.detailLabel}>
              {intl.formatMessage({ id: 'ListingPage.SectionPacks.installments' })}
            </span>
            <span>
              <FormattedMessage id={numberOfInstallments} />
            </span>
          </li>
        ) : null}
        {installmentFormat && installmentPrice ? (
          <li key={'installmentFormat'} className={css.detailsRow}>
            <span className={css.detailLabel}>
              {intl.formatMessage({ id: 'ListingPage.SectionPacks.price' })}
            </span>
            <span>
              <FormattedMessage id={installmentFormat} />
            </span>
          </li>
        ) : null}
        {downPaymentFormat && downPaymentPrice ? (
          <li key={'installmentFormat'} className={css.detailsRow}>
            <span className={css.detailLabel}>
              {intl.formatMessage({ id: 'ListingPage.SectionPacks.price' })}
            </span>
            <span>
              <FormattedMessage id={downPaymentFormat} />
            </span>
          </li>
        ) : null}
      </ul>
    </div>
  ) : null;
};

export default SectionPacks;
