import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { getTransitionsNeedingProviderAttention } from '../transactions/transaction';

import { authInfo } from './auth.duck';
import { fetchStripeAccount, stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { util as sdkUtil } from '../util/sdkLoader';
import {
  cancelMembership,
  createAffiliationViaApi,
  createCheckoutSession,
  puchaseGiftCardViaApi,
} from '../util/api';
import { SERVICE_LISTING } from '../config/configListing';
import { fetchUserVouchers } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const UPDATE_PROFILE_LANGUAGES_REQUEST = 'app/user/UPDATE_PROFILE_LANGUAGES_REQUEST';
export const UPDATE_PROFILE_LANGUAGES_SUCCESS = 'app/user/UPDATE_PROFILE_LANGUAGES_SUCCESS';
export const UPDATE_PROFILE_LANGUAGES_ERROR = 'app/user/UPDATE_PROFILE_LANGUAGES_ERROR';

export const SHOW_EMAIL_VERIFICATION_POPUP = 'app/user/SHOW_EMAIL_VERIFICATION_POPUP';

export const CREATE_MEMBERSHIP_REQUEST = 'app/user/CREATE_MEMBERSHIP_REQUEST';
export const CREATE_MEMBERSHIP_SUCCESS = 'app/user/CREATE_MEMBERSHIP_SUCCESS';
export const CREATE_MEMBERSHIP_ERROR = 'app/user/CREATE_MEMBERSHIP_ERROR';

export const CANCEL_MEMBERSHIP_REQUEST = 'app/user/CANCEL_MEMBERSHIP_REQUEST';
export const CANCEL_MEMBERSHIP_SUCCESS = 'app/user/CANCEL_MEMBERSHIP_SUCCESS';
export const CANCEL_MEMBERSHIP_ERROR = 'app/user/CANCEL_MEMBERSHIP_ERROR';

export const ALL_SERVICE_LISTINGS_REQUEST = 'app/user/ALL_SERVICE_LISTINGS_REQUEST';
export const ALL_SERVICE_LISTINGS_SUCCESS = 'app/user/ALL_SERVICE_LISTINGS_SUCCESS';
export const ALL_SERVICE_LISTINGS_ERROR = 'app/user/ALL_SERVICE_LISTINGS_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/user/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/user/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/user/UPDATE_PROFILE_ERROR';

export const CREATE_AFFILIATION_REQUEST = 'app/user/CREATE_AFFILIATION';
export const CREATE_AFFILIATION_SUCCESS = 'app/user/CREATE_AFFILIATION_SUCCESS';
export const CREATE_AFFILIATION_ERROR = 'app/user/CREATE_AFFILIATION_ERROR';

export const CREATE_GIFT_CARD_REQUEST = 'app/user/CREATE_GIFT_CARD_REQUEST';
export const CREATE_GIFT_CARD_SUCCESS = 'app/user/CREATE_GIFT_CARD_SUCCESS';
export const CREATE_GIFT_CARD_ERROR = 'app/user/CREATE_GIFT_CARD_ERROR';
// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserProgress: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  updateProfileLanguagesInProgress: false,
  emailVerifyPopupOpen: false,
  createMembershipInProgress: false,
  createMembershipError: null,
  plan: null,
  cancelMembershipInProgress: false,
  cancelMembershipError: null,
  allServiceListings: [],
  allServiceListingsError: null,
  allServiceListingsInProgress: false,
  anyListingHaveAvailability: false,
  anyListingHaveMissingAvailability: false,
  updateProfileInProgress: false,
  updateProfileError: null,
  affiliationInProgress: false,
  affiliationError: null,
  giftCardInProgress: null,
  giftCardError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null, currentUserProgress: true };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
        currentUserProgress: false,
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload, currentUserProgress: false };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };
    case UPDATE_PROFILE_LANGUAGES_REQUEST:
      return {
        ...state,
        updateProfileLanguagesInProgress: true,
      };
    case UPDATE_PROFILE_LANGUAGES_SUCCESS:
      return {
        ...state,
        updateProfileLanguagesInProgress: false,
      };
    case UPDATE_PROFILE_LANGUAGES_ERROR:
      return {
        ...state,
        updateProfileLanguagesInProgress: false,
      };
    case SHOW_EMAIL_VERIFICATION_POPUP:
      return {
        ...state,
        emailVerifyPopupOpen: payload,
      };
    case CREATE_MEMBERSHIP_REQUEST:
      return {
        ...state,
        createMembershipInProgress: true,
        createMembershipError: null,
        plan: payload,
      };
    case CREATE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        createMembershipInProgress: false,
      };
    case CREATE_MEMBERSHIP_ERROR:
      return {
        ...state,
        createMembershipInProgress: false,
        createMembershipError: payload,
      };
    case CANCEL_MEMBERSHIP_REQUEST:
      return {
        ...state,
        cancelMembershipInProgress: true,
        cancelMembershipError: null,
      };
    case CANCEL_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        cancelMembershipInProgress: false,
      };
    case CANCEL_MEMBERSHIP_ERROR:
      return {
        ...state,
        cancelMembershipInProgress: false,
        cancelMembershipError: payload,
      };
    case ALL_SERVICE_LISTINGS_REQUEST:
      return {
        ...state,
        allServiceListingsError: null,
        allServiceListingsInProgress: true,
        anyListingHaveAvailability: false,
        allServiceListings: [],
        anyListingHaveMissingAvailability: false,
      };
    case ALL_SERVICE_LISTINGS_SUCCESS:
      return {
        ...state,
        // anyListingHaveAvailability: payload.some(
        //   listing => listing.attributes.availabilityPlan !== null
        // ),
        // anyListingHaveMissingAvailability: payload.some(
        //   listing => listing.attributes.availabilityPlan === null
        // ),
        allServiceListings: [...state.allServiceListings, ...payload],
        allServiceListingsInProgress: false,
      };
    case ALL_SERVICE_LISTINGS_ERROR:
      return {
        ...state,
        allServiceListingsError: payload,
        allServiceListingsInProgress: false,
        anyListingHaveAvailability: false,
        anyListingHaveMissingAvailability: false,
      };
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfileInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateProfileInProgress: false,
        updateProfileError: payload,
      };
    case CREATE_AFFILIATION_REQUEST:
      return {
        ...state,
        affiliationInProgress: true,
        affiliationError: null,
      };
    case CREATE_AFFILIATION_SUCCESS:
      return {
        ...state,
        affiliationInProgress: false,
      };
    case CREATE_AFFILIATION_ERROR:
      return {
        ...state,
        affiliationInProgress: false,
        affiliationError: payload,
      };
    case CREATE_GIFT_CARD_REQUEST:
      return {
        ...state,
        giftCardInProgress: true,
        giftCardError: null,
      };
    case CREATE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        giftCardInProgress: false,
      };
    case CREATE_GIFT_CARD_ERROR:
      return {
        ...state,
        giftCardInProgress: false,
        giftCardError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const updateProfileLanguagesRequest = () => ({
  type: UPDATE_PROFILE_LANGUAGES_REQUEST,
});

export const updateProfileLanguagesSuccess = () => ({
  type: UPDATE_PROFILE_LANGUAGES_SUCCESS,
});

export const updateProfileLanguagesError = e => ({
  type: UPDATE_PROFILE_LANGUAGES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //
export const purchaseGiftCard = params => (dispatch, getState, sdk) => {
  dispatch({ type: CREATE_GIFT_CARD_REQUEST });
  const { currentUser } = getState().user;
  if (!currentUser) {
    return Promise.resolve();
  }

  return puchaseGiftCardViaApi(params)
    .then(response => {
      dispatch({ type: CREATE_GIFT_CARD_SUCCESS });
      const url = response?.url;
      if (url) window.location.assign(url);
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch({ type: CREATE_GIFT_CARD_ERROR, payload: storableError(e) });
    });
};

export const createAffiliation = () => (dispatch, getState, sdk) => {
  dispatch({ type: CREATE_AFFILIATION_REQUEST });
  const { currentUser } = getState().user;
  if (!currentUser) {
    return Promise.resolve();
  }

  return createAffiliationViaApi({})
    .then(response => {
      dispatch({ type: CREATE_AFFILIATION_SUCCESS });
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch({ type: CREATE_AFFILIATION_ERROR, payload: storableError(e) });
    });
};
export const updateProfileWhenSkip = () => (dispatch, getState, sdk) => {
  dispatch({ type: UPDATE_PROFILE_REQUEST });
  const { currentUser } = getState().user;
  if (!currentUser) {
    return Promise.resolve();
  }

  return sdk.currentUser
    .updateProfile({ publicData: { skippedAvailabilityCheck: true } }, { expand: true })
    .then(response => {
      dispatch({ type: UPDATE_PROFILE_SUCCESS });
      dispatch(fetchCurrentUser());
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch({ type: UPDATE_PROFILE_ERROR, payload: storableError(e) });
    });
};
const fetchListings = (page = 1, currentUserId) => (dispatch, getState, sdk) => {
  console.log('fetchListings', page, currentUserId);
  return sdk.ownListings
    .query({
      // authorId: currentUserId,
      page,
      perPage: 100,
      pub_listingType: SERVICE_LISTING,
    })
    .then(response => {
      return response;
    })
    .catch(e => {
      console.log('e', e);
      throw e;
    });
};
export const fetchAllServiceListings = () => (dispatch, getState, sdk) => {
  dispatch({ type: ALL_SERVICE_LISTINGS_REQUEST });
  const { currentUser } = getState().user;
  if (!currentUser) {
    return Promise.resolve();
  }
  const isProfessional = currentUser?.attributes?.profile?.publicData?.userType === 'professional';
  if (!isProfessional) {
    return Promise.resolve();
  }

  return dispatch(fetchListings(1, currentUser.id.uuid))
    .then(response => {
      const { data, meta } = response.data;
      const { totalItems, totalPages } = meta;
      dispatch({ type: ALL_SERVICE_LISTINGS_SUCCESS, payload: data });
      const promises = [];
      for (let i = 2; i <= totalPages; i++) {
        promises.push(dispatch(fetchListings(i, currentUser.id.uuid)));
      }
      return Promise.all(promises)
        .then(responses => {
          responses.forEach(res => {
            dispatch({ type: ALL_SERVICE_LISTINGS_SUCCESS, payload: res.data.data });
          });
        })
        .catch(e => {
          dispatch({ type: ALL_SERVICE_LISTINGS_ERROR, payload: e });
        });
    })
    .catch(e => {
      console.log(storableError(e));
      dispatch({ type: ALL_SERVICE_LISTINGS_ERROR, payload: storableError(e) });
    });
};
export const createMembership = (plan, isActive) => (dispatch, getState, sdk) => {
  dispatch({ type: CREATE_MEMBERSHIP_REQUEST, payload: plan });
  return createCheckoutSession({ plan, isActive })
    .then(res => {
      window.location.href = res.url;
      dispatch({ type: CREATE_MEMBERSHIP_SUCCESS });
      return res;
    })

    .catch(e => {
      dispatch({ type: CREATE_MEMBERSHIP_ERROR, payload: storableError(e) });
    });
};
export const updateProfileLanguage = language => (dispatch, getState, sdk) => {
  dispatch(updateProfileLanguagesRequest());
  console.log('language', language);
  const { currentUser } = getState().user;
  if (!currentUser) {
    document.cookie = 'language=' + language;
    window.location.reload(true);
    return Promise.resolve();
  }
  return sdk.currentUser
    .updateProfile({ publicData: { language } }, { expand: true })
    .then(response => {
      document.cookie = 'language=' + language;
      window.location.reload(true);
      dispatch(updateProfileLanguagesSuccess());
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch(updateProfileLanguagesError(storableError(e)));
      throw e;
    });
};

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    perPage: 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    perPage: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  const transitionsNeedingAttention = getTransitionsNeedingProviderAttention();
  if (transitionsNeedingAttention.length === 0) {
    // Don't update state, if there's no need to draw user's attention after last transitions.
    return;
  }

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsNeedingAttention,
    page: 1,
    perPage: NOTIFICATION_PAGE_SIZE,
  };

  dispatch(fetchCurrentUserNotificationsRequest());
  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }
      // dispatch(fetchAllServiceListings());
      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      dispatch(fetchAllServiceListings());
      dispatch(fetchUserVouchers());
      dispatch(fetchStripeAccount());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const cancelStripeMembership = () => (dispatch, getState, sdk) => {
  dispatch({ type: CANCEL_MEMBERSHIP_REQUEST });
  return cancelMembership({})
    .then(() => {
      dispatch({ type: CANCEL_MEMBERSHIP_SUCCESS });
      return dispatch(fetchCurrentUser());
    })
    .catch(e => {
      dispatch({ type: CANCEL_MEMBERSHIP_ERROR, payload: e });
      throw e;
    });
};
